import Jumbotron from "../components/Jumbotron";
import { Check } from 'lucide-react';
import { useState } from "react";
// import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import company from '../assets/company.jpg';
import company3 from '../assets/company3.jpg';

function About() {
  const [activeTab, setActiveTab] = useState('vission');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  
  return (
    <>
      <Jumbotron page="About" />
      <section className=" bg-mjavsLight pt-14 pb-16 md:pt-40 md:pb-28">
        <div className="container space-y-12 flex flex-col md:gap-8 md:flex-row md:space-y-0">
          <div className="mb-12 w-full md:mb-0 md:w-6/12 isolate">
            <div className="relative w-full h-full md:top-[-2.8125rem]">
              <div className="relative w-10/12 h-80 left-[-2.8125rem] bg-mjavsGreen mx-auto" />
                <img 
                  src={company}
                  className="absolute right-[0] top-10 w-11/12 h-full object-cover shadow-lg" alt="about-us"
                  loading="lazy"
                />
            </div>
          </div>

          <div className="w-full md:w-6/12">
            <div className="flex h-full items-center text-black">
              <div>
                <span className="block mb-2 text-lg text-mjavsGold tracking-[0.21rem] font-medium">
                  ABOUT US
                </span>
                <h2 className="font-rajdhani mb-8 text-3xl font-bold text-mjavsGreen md:text-4xl lg:text-5xl">Your Gateway to Exceptional PVC Solutions</h2>
                <div>
                  <p className="mb-8 pb-2 text-mjavsGreen font-medium leading-8 text-lg lg:pb-0">
                    A trailblazing force in the realm of Polyvinyl Chloride (PVC) solutions. Established in 2011, we have become synonymous with innovation, quality, and unwavering commitment to excellence. Our journey has been marked by a passion for redefining industry standards and delivering PVC products that not only meet but exceed expectations.
                  </p>
                  <div className="grid grid-cols- gap-3 sm:grid-cols-1 md:grid-cols-2 text-mjavsGreen">
                    <article className="flex items-center bg-mjavsLight p-3 gap-3 font-medium">
                      <span className="p-2 bg-mjavsGold rounded-full text-mjavsGreen">
                        <Check />
                      </span>
                      <p>Trusted by Industry Leaders</p>
                    </article>
                    <article className="flex items-center bg-mjavsLight p-3 gap-3 font-medium">
                      <span className="p-2 bg-mjavsGold rounded-full text-mjavsGreen">
                        <Check />
                      </span>
                      <p>Quality Assurance</p>
                    </article>
                    <article className="flex items-center bg-mjavsLight p-3 gap-3 font-medium">
                      <span className="p-2 bg-mjavsGold rounded-full text-mjavsGreen">
                        <Check />
                      </span>
                      <p> Innovation Hub</p>
                    </article>
                    <article className="flex items-center bg-mjavsLight p-3 gap-3 font-medium">
                      <span className="p-2 bg-mjavsGold rounded-full text-mjavsGreen">
                        <Check />
                      </span>
                      <p>Unparalleled Expertise</p>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 md:py-28">
        <div className="container flex flex-col items-start md:gap-8 md:flex-row">
          <div className="w-full md:w-6/12">
            <div className="flex h-full items-center text-black">
              <div>
                <span className="block mb-2 text-lg text-mjavsGold tracking-[0.21rem] font-medium">
                  ABOUT US
                </span>
                <h2 className="font-rajdhani text-3xl font-bold text-mjavsGreen md:text-4xl lg:text-5xl">Experienced Workers</h2>
                <p className="my-7 text-mjavsDark">
                  The MJAVS Project is not just a technological endeavor; it's a commitment to a vision of PVC excellence. Through our mission and strategic approach, we aim to shape the future of PVC solutions, making a positive impact on industries, communities, and the environment.
                </p>
                <div className="flex flex-wrap justify-start items-start gap-5 mb-7">
                  <button
                    className={`py-2 px-8 text-mjavsGreen bg-mjavsLight font-semibold border-0 rounded-md hover:bg-mjavsGold ${
                      activeTab === 'vission' ? 'bg-[#e3cca1]' : ''
                    }`}
                    onClick={() => handleTabChange('vission')}
                  >
                    Vision
                  </button>
                  <button
                    className={`py-2 px-8 text-mjavsGreen bg-mjavsLight font-semibold border-0 rounded-md hover:bg-mjavsGold ${
                      activeTab === 'mission' ? 'bg-[#e3cca1]' : ''
                    }`}
                    onClick={() => handleTabChange('mission')}
                  >
                    Mission
                  </button>
                  <button
                    className={`py-2 px-8 text-mjavsGreen bg-mjavsLight font-semibold border-0 rounded-md hover:bg-mjavsGold ${
                      activeTab === 'strategy' ? 'bg-[#e3cca1]' : ''
                    }`}
                    onClick={() => handleTabChange('strategy')}
                  >
                    Strategy
                  </button>
                </div>
                <div className="text-mjavsDark">
                  {activeTab === 'vission' && (
                    <div className="grid">
                      <p>
                        At MJAVS, we envision a future where PVC solutions play a pivotal role in shaping industries and communities. We aspire to be at the forefront of this transformation, driving innovation and setting new benchmarks for quality and reliability.
                      </p>
                    </div>
                  )}
                  {activeTab === 'mission' && (
                    <div className="grid">
                      <p>
                        Our mission is to solidify MJAVS ENTERPRISES NIGERIA LIMITED as a pioneer in the PVC pipe industry by leveraging innovation and excellence. We aim to provide superior PVC solutions that meet and exceed the evolving needs of our customers, contributing to the growth and sustainability of industries we serve.
                      </p>
                    </div>
                  )}
                  {activeTab === 'strategy' && (
                    <div className="grid">
                      <ul className="space-y-3">
                        <li>Establish an innovation hub within the project to explore new materials, manufacturing techniques, and product designs.</li>
                        <li>Emphasize customization options, allowing customers to tailor PVC pipes to their specific project requirements.</li>
                        <li>Foster a culture of continuous improvement, encouraging team members to contribute innovative ideas.</li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mb-12 w-full md:mb-0 md:w-6/12 isolate">
            <div className={`relative overflow-hidden h-[23rem]`} >
              <img 
                src={company3}
                className="absolute w-full h-full object-cover shadow-lg" alt="about-us"
                loading="lazy"
              />
            </div>
            <div className="container px-6 md:px-12">
              <div className="block bg-white px-6 py-12 shadow-lg -mt-20 backdrop-blur-[30px]">
                <div className="flex flex-wrap justify-center text-center">
                    <p>
                      At MJAVS we are not just a company, we are a commitment to excellence, reliability, and customer satisfaction.
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="py-16 md:py-28 bg-mjavsLight">
        <div className="max-w-[42rem] mx-auto text-center space-y-5 px-[1rem] mb-14 md:px-0">
          <p className="block mb-2 text-l text-mjavsGold tracking-[0.21rem]">OUR TEAM</p>
          <h2 className="font-bold text-mjavsGreen font-rajdhani text-4xl lg:text-5xl">Company Leadership</h2>
        </div>
        
        <div className="container text-center">
          <div className="grid gap-12 md:grid-cols-3">
            {[...Array(3)].map((_, index) => (
              <div key={index} className="relative block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
                <div className="flex">
                  <div
                    className="relative mx-4 -mt-4 w-full h-80 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
                    <img src={logo} className="w-full" alt="hhh" />
                  </div>
                </div>
                <div className="p-6 text-mjavsGreen">
                  <h5 className="mb-2 text-xl font-bold font-rajdhani">Halley Frank</h5>
                  <h6 className="mb-4 font-medium">
                    Marketing Specialist
                  </h6>
                  <div className="flex justify-center items-center gap-5">
                    <div className="inline-block rounded-full bg-mjavsGold p-2">
                      <FaFacebookF />
                    </div>
                    <div className="inline-block rounded-full bg-mjavsGold p-2">
                      <FaTwitter />
                    </div>
                    <div className="inline-block rounded-full bg-mjavsGold p-2">
                      <FaInstagram />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section> */}
    </>
  )
}

export default About