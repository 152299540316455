import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { UsersRound, Heart, Briefcase } from 'lucide-react';
import { responsive, testimonials } from '../data/data';

function Testimonials() {
  
  return (
    <section className="bg-mjavsGreen py-16 md:py-28">
        {/* <div className="absolute container mx-auto left-0 right-0 lg:-mt-48 -mt-56">
          <div className="block bg-mjavsGold px-6 py-12 md:py-16 md:px-12 backdrop-blur-md">
            <div className="flex flex-col items-start justify-center space-y-5 lg:flex-row lg:justify-between lg:items-center lg:space-y-0">
              <div className="space-y-4 lg:w-1/2">
                <p className="block text-l text-mjavsGreen tracking-[0.21rem]">
                  NEWSLATTER
                </p>
                <span className="block max-w-[26rem] font-bold text-mjavsGreen text-2xl font-rajdhani md:text-4xl">
                  sign up for newslatter & get lattest news & update
                </span>
              </div>
              <div className="flex-row md:flex w-full lg:w-1/2">
                <div className="relative mb-3 w-full md:mr-3 md:mb-0" data-te-input-wrapper-init>
                  <input 
                    type="text"
                    placeholder="Email Address"
                    className="pl-2 placeholder:text-mjavsGreen bg-white w-full py-6 focus:outline-none md:pl-8" />
                  <button className="font-rajdhani text-sm absolute right-2 bottom-1/2 translate-y-1/2 bg-mjavsGold hover:bg-mjavsGreen text-mjavsGreen font-bold py-4 px-2 hover:text-white transition-all duration-500 ease-in-out hover:bg-gradient-to-r from-mjavsGreen to-mjavsGreen delay-100 focus:outline-none md:px-8 md:text-xl">
                  SUBSCRIBE
                </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container space-y-10">
          <div className="flex flex-col space-y-3 md:gap-8 md:flex-row md:space-y-0">
            <div className="md:w-2/5 space-y-5">
              <p className="block mb-2 text-l text-mjavsGold tracking-[0.21rem]">
                CLIENT TESTIMONIALS
              </p>
              <h2 className="font-bold text-white text-4xl font-rajdhani lg:text-5xl">Our Achievments</h2>
            </div>
            <div className="md:w-3/5">
              <p className="text-white leading-8">
                What our clients are saying about their experiences with MJAVS. We take pride in building lasting relationships with our clients, and their testimonials reflect the commitment to excellence that defines our company.
              </p>
            </div>
          </div>
          

          <div className="mx-auto space-y-12 flex flex-col lg:gap-8 lg:flex-row lg:space-y-0">
            <div className="mb-12 w-full md:mb-0 lg:w-1/3">
              <div className="grid gap-3 grid-cols-1 md:grid-cols-3 lg:grid-cols-1">
                <article className="flex items-center gap-8 font-medium">
                  <span className="p-6 bg-mjavsGold rounded-sm text-mjavsGreen">
                    <Briefcase className="w-10 h-10"/>
                  </span>
                  <span className="text-white">
                    <p className="text-4xl">12</p>
                    <p>Years of experience</p>
                  </span>
                </article>
                <article className="flex items-center gap-8 font-medium">
                  <span className="p-6 bg-mjavsGold rounded-sm text-mjavsGreen">
                    <Heart className="w-10 h-10"/>
                  </span>
                  <span className="text-white">
                    <p className="text-4xl">98%</p>
                    <p>Satisfaction</p>
                  </span>
                </article>
                <article className="flex items-center gap-8 font-medium">
                  <span className="p-6 bg-mjavsGold rounded-sm text-mjavsGreen">
                    <UsersRound className="w-10 h-10"/>
                  </span>
                  <span className="text-white">
                    <p className="text-4xl">10k+</p>
                    <p>Happy Customers</p>
                  </span>
                </article>
              </div>
            </div>

            <Carousel
              infinite
              autoPlay
              arrows={false}
              responsive={responsive}
              className="w-full lg:w-2/3">
              {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-white rounded-md p-6">
                <div className="flex gap-5 items-center">
                  <div className="mb-6 flex justify-center">
                    <img
                      src={`https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(${index+2}).jpg`}
                      className="w-20 rounded-full shadow-lg"
                      alt="aaaaa"
                    />
                  </div>
                  <span className="block">
                    <h5 className="mb-2 text-lg font-bold">{testimonial.author}</h5>
                    <h6 className="mb-4 font-medium text-primary dark:text-primary-400">
                      {testimonial.position}
                    </h6>
                  </span>
                </div>
                <p className="mb-4">{testimonial.content}</p>
              </div>
              ))}
            </Carousel>
          </div>
        </div>
    </section>
  )
}

export default Testimonials