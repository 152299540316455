import { Link } from 'react-router-dom';
import { Phone, Mail, MapPin } from "lucide-react";
import logo from '../assets/logo.png';

function Footer() {
  return (
    <>
      <footer className="bg-mjavsGreen text-white">
        <div className="container py-10 font-rajdhani space-y-10">
          <div className="grid grid-cols-1 gap-8 lg:col-span-2 sm:grid-cols-2 lg:grid-cols-4">
            <div>
              <img src={logo} className="h-10 sm:h-12 filter grayscale brightness-0 invert" alt="logo" />
              <p className="max-w-xs mt-4 font-montserrat">
                MJAVS Nigeria, Shaping Excellence, Delivering PVC Solutions.
              </p>
            </div>
            <div>
              <p className="font-bold text-mjavsGold text-2xl">
                Company
              </p>
              <nav className="flex flex-col mt-4 space-y-2 font-montserrat">
                <Link to="/about" className="hover:text-mjavsGold hover:font-bold cursor-pointer"> About </Link>
                <Link to="/services" className="hover:text-mjavsGold hover:font-bold cursor-pointer"> Services</Link>
                <Link to="/about" className="hover:text-mjavsGold hover:font-bold cursor-pointer"> History </Link>
              </nav>
            </div>
            <div>
              <p className="font-bold text-mjavsGold text-2xl">
                Helpful Links
              </p>
              <nav className="flex flex-col mt-4 space-y-2 font-montserrat">
                <Link to="/contact" className="hover:text-mjavsGold hover:font-bold cursor-pointer"> Contact </Link>
                <Link to="/contact" className="hover:text-mjavsGold hover:font-bold cursor-pointer"> Get Quote </Link>
              </nav>
            </div>
            <div>
              <p className="font-bold text-mjavsGold text-2xl">
                Contact
              </p>
              <div className="mt-4 space-y-4 font-montserrat">
                <div className="flex items-start justify-start gap-4">
                  <MapPin />
                  <span>27Blk Gwagwalada, Abuja</span>
                </div>

                <div className="flex items-start justify-start gap-4">
                  <Mail />
                  <span>info@mjavs.org</span>
                </div>

                <div className="flex items-start justify-start gap-4">
                  <Phone />
                  <span>0029129102320</span>
                </div>
              </div>
            </div>
          </div> 
          <hr className="h-[1px] border-t-0 bg-mjavsLight" />
          <p className="text-white text-center font-montserrat">
            &copy; 2011 Mjavs | All Rights Reserved
          </p>
        </div>
      </footer>
    </>
  )
}

export default Footer