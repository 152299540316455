import { useEffect } from "react";
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const PageWrapper = ({ children }) => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      x: "-100%",
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      x: "100%",
      transition: {
        duration: 0.5,
      },
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on page change
  }, [location.pathname]);

  return (
    <AnimatePresence >
      <motion.div
        key={location.pathname}
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageVariants}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

PageWrapper.defaultProps = {
  children: () => {},
};

PageWrapper.propTypes = {
  children: PropTypes.object,
};

export default PageWrapper;