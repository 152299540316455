import { motion } from "framer-motion";
import ShuffleGrid from "./ui/Shuffle-Grid";
import { Phone, Clock, MapPin } from "lucide-react";

const Hero = () => {
  return (
    <>
      <section className="py-12">
        <div className="container grid grid-cols-1 md:grid-cols-2 items-center gap-8 text-center md:text-left">
          <div>
            <span className="block mb-4 text-xl text-mjavsGreen font-rajdhani font-semibold">
              Welcome to
            </span>
            <h3 className="text-4xl md:text-6xl font-bold capitalize">
              mjavs <br /> enterprises nigeria limited
            </h3>
            <p className="text-base md:text-lg my-4 md:my-6">
              Where innovation meets quality in the world of Polyvinyl Chloride (PVC). As a leading Company, we are dedicated to redefining standards and delivering cutting-edge PVC solutions that stand the test of time.
            </p>
          </div>
          <div className="hidden md:block">
            <ShuffleGrid />
          </div>
        </div>
      </section>
      <section className="bg-mjavsDark font-leagueSpartan">
        <div className="container py-10 flex flex-col justify-between items-start gap-5 lg:items-center lg:flex-row lg:py-0">
          <motion.div 
            className="flex flex-row justify-center gap-10 items-center group/item lg:bg-[url(./assets/phone.png)] bg-no-repeat bg-center lg:py-14"
            whileHover={{ scale: 1.05, rotate: [0, -5, 5, -5, 5, 0], transition: { duration: 0.5 } }}
          >
            <Phone className="text-mjavsGreen h-10 w-10"/>
            <span className="text-white font-bold">
              <p className="stat-desc uppercase text-sm">Have a question? call us now</p>
              <h5 className="stat-title text-xl">Total Likes</h5>
            </span>
          </motion.div>
          <div className="bg-gray-500 w-full h-[1px] lg:hidden">
          </div>
          <motion.div
            className="flex flex-row justify-center gap-10 items-center group/item lg:bg-[url(./assets/clock.png)] bg-no-repeat bg-center lg:py-14"
            whileHover={{ scale: 1.05, rotate: [0, -5, 5, -5, 5, 0], transition: { duration: 0.5 } }}
          >
            <Clock className="text-mjavsGreen h-10 w-10" />
            <span className="text-white font-bold">
              <p className="stat-desc uppercase text-sm">We are open monday-friday</p>
              <h5 className="stat-title text-xl">08:00 - 05:00</h5>
            </span>
          </motion.div>
          <div className="bg-gray-500 w-full h-[1px] lg:hidden"></div>
          <motion.div 
            className="flex flex-row justify-center gap-10 items-center group/item lg:bg-[url(./assets/pointer.png)] bg-no-repeat bg-center lg:py-14"
            whileHover={{ scale: 1.05, rotate: [0, -5, 5, -5, 5, 0], transition: { duration: 0.5 } }}
          >
            <MapPin className="text-mjavsGreen h-10 w-10"/>
            <span className="text-white font-bold">
              <p className="stat-desc uppercase text-sm">Need help? our address</p>
              <h5 className="stat-title text-xl">27BLK GWAGWALADA, ABUJA</h5>
            </span>
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default Hero;