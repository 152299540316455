import { pvcProducts } from '../data/data';
import Jumbotron from "../components/Jumbotron";
import company2 from '../assets/company2.jpg';

function Services() {
  
  return (
    <>
      <Jumbotron page="Services" />
      <section className="py-16 md:py-28">
        <div className="container">
          <div className="flex flex-col space-y-3 md:gap-8 md:flex-row md:space-y-0">
            <div className="md:w-2/5 space-y-5">
              <p className="block mb-2 text-l font-medium text-mjavsGold tracking-[0.21rem]">
                WHAT WE DO
              </p>
              <h2 className="font-bold text-mjavsGreen text-4xl font-rajdhani lg:text-5xl">Services for You</h2>
            </div>
            <div className="md:w-3/5">
              <p className="leading-8 text-mjavsDark">
                At MJAVS, we take pride in offering a comprehensive range of Polyvinyl Chloride (PVC) products tailored to meet the diverse needs of our customers. Our commitment to excellence and innovation is reflected in every item we produce.
              </p>
            </div>
          </div>

          <div className="grid gap-12 md:grid-cols-2 lg:grid-cols-3 mt-16">
            {pvcProducts.map((product, index) => (
              <div key={index} className="rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">

                <div className="p-6 text-mjavsGreen">
                  <h5 className="mb-2 text-xl font-semibold font-rajdhani">{product.category}</h5>
                  <p className="mb-4 leading-8 text-base text-mjavsDark">{product.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-16 md:py-28 bg-mjavsLight">
        <div className="container flex flex-col items-start gap-8 md:flex-row">
          <div className="w-full md:w-6/12">
            <div className="flex h-full items-center text-black">
              <div>
                <span className="block mb-2 text-lg text-mjavsGold tracking-[0.21rem] font-medium">
                  WELCOME TO MJAVS
                </span>
                <h2 className="font-rajdhani text-3xl font-bold text-mjavsGreen md:text-4xl lg:text-5xl">Why Choose MJAVS ENTERPRISES</h2>
                <p className="my-7 text-mjavsDark leading-8">
                  At MJAVS ENTERPRISES NIGERIA LIMITED, we are not just a supplier, we are your comprehensive partner for PVC excellence. Explore our expanded services and discover how we can contribute to the success of your projects.
                </p>
                <div className="space-y-3">
                  <p><span className="font-bold text-mjavsGreen">Quality Assurance:</span> Rigorous testing and adherence to industry standards ensure the durability and reliability of our PVC products.</p>
                  <p><span className="font-bold text-mjavsGreen">Innovation:</span> Stay ahead with our innovative PVC solutions designed to meet the evolving needs of various industries.</p>
                  <p><span className="font-bold text-mjavsGreen">QCustomization:</span> Tailor-made solutions to fit the unique requirements of your projects.</p>
                  <p><span className="font-bold text-mjavsGreen">Industry Expertise:</span> Benefit from our decade-long experience and industry insights.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-12 w-full md:mb-0 md:w-6/12">
            <div className={`overflow-hidden h-96 md:h-[34rem]`} >
              <img 
                src={company2}
                className="w-full object-cover shadow-lg" alt="about-us"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Services