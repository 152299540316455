import React, { useState } from "react";
import Jumbotron from "../components/Jumbotron";
import { Phone, MailOpen, MapPin } from "lucide-react";

function Contact() {
  const initialState = {
    fullName: "",
    phoneNumber: "",
    emailAddress: "",
    subject: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [formErrors, setFormErrors] = useState(initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = {};
    for (const field in formData) {
      if (formData[field].trim() === "") {
        errors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      } else if (field === "emailAddress" && !isValidEmail(formData[field])) {
        errors[field] = "Invalid email address";
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    console.log("Form submitted:", formData);
    // Reset form state after successful submission
    setFormData(initialState);
    setFormErrors(initialState);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  return (
    <>
      <Jumbotron page="Contact" />
      <section className="contact space-y-8 py-12 md:py-32 md:space-y-16">
        <div className="max-w-[42rem] mx-auto text-center space-y-5 p-[1rem] md:p-0">
          <p className="block mb-2 text-l font-medium text-mjavsGold tracking-[0.21rem]">
            CONTACT US
          </p>
          <h2 className="font-bold text-mjavsGreen font-rajdhani text-4xl lg:text-5xl">Get In Touch</h2>
        </div>
        <div className="container">
          <div className="grid gap-3 grid-cols-1 text-mjavsGreen md:grid-cols-3">
            <article className="flex items-center gap-8 font-medium">
              <span className="p-5 bg-mjavsGold rounded-md">
                <MapPin className="w-10 h-10"/>
              </span>
              <span className="space-y-5">
                <p className="text- font-semibold lowercase">Our Address</p>
                <p>27BLK Ckontagora Estate <br /> Gwagwalada, Abuja, FCT</p>
              </span>
            </article>
            <article className="flex items-center gap-8 font-medium">
              <span className="p-5 bg-mjavsGold rounded-md">
                <Phone className="w-10 h-10"/>
              </span>
              <span className="space-y-5">
                <p className="text- font-semibold">Our Phone</p>
                <p>Office Phone : 0029129102320 <br /> Mobile : 000 2324 39493</p>
              </span>
            </article>
            <article className="flex items-center gap-8 font-medium">
              <span className="p-5 bg-mjavsGold rounded-md">
                <MailOpen className="w-10 h-10"/>
              </span>
              <span className="space-y-5">
                <p className="text- font-semibold">Our Email</p>
                <p>Main Email : name@mjavs.com <br /> Enquiries : Info@mjavs.com</p>
              </span>
            </article>
          </div>
        </div>
      </section>
      <section className="contact pb-12 md:pb-32">
        <div className="">
            <div className="relative overflow-hidden bg-cover bg-no-repeat h-[35rem]">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.289935856698!2d7.084446374629268!3d8.945408291112631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e896f6cf954fd%3A0x7a0e457fddcb5969!2sKontangora%20estate!5e0!3m2!1sen!2sgh!4v1704404336365!5m2!1sen!2sgh" width="100%" height="100%" loading="lazy" title="Google Maps"></iframe>
            </div>
            <div className="container">
              <div className="block bg-white mt-[-10rem] backdrop-blur-[30px] shadow-lg">
                <div className="flex flex-col md:flex-row">
                  <div className="w-full md:w-1/3 p-10 bg-mjavsGreen space-y-5">
                    <p className="block mb-2 text-l font-medium text-mjavsGold tracking-[0.21rem]">
                      GET IN TOUCH
                    </p>
                    <h4 className="font-bold text-white text-4xl font-rajdhani lg:text-5xl">
                      Write Us a Message
                    </h4>
                    <p className="text-white leading-8">
                      Whether you have inquiries about our PVC products, need assistance with a project, or want to explore collaboration opportunities, we are here to assist you. Contact MJAVS ENTERPRISES NIGERIA LIMITED through the following channels:
                    </p>
                  </div>

                  <div className="w-full md:w-2/3 p-3 md:p-10">
                    <form className="space-y-7" onSubmit={handleSubmit}>
                      <div className="grid grid-cols-1 gap-10 md:grid-cols-2 sm:grid-cols-2">
                        <div>
                          <input
                            type="text"
                            name="fullName"
                            placeholder="Full Name"
                            className={`w-full placeholder:text-mjavsGreen border-2 bg-white p-4 focus:outline-none ${
                              formErrors.fullName && "border-red-500"
                            }`}
                            onChange={handleInputChange}
                          />
                          <div className="text-red-500 text-sm mt-1">
                            {formErrors.fullName}
                          </div>
                        </div>
                        <div>
                          <input
                            type="text"
                            name="phoneNumber"
                            placeholder="Phone Number"
                            className={`w-full placeholder:text-mjavsGreen border-2 bg-white p-4 focus:outline-none ${
                              formErrors.phoneNumber && "border-red-500"
                            }`}
                            onChange={handleInputChange}
                          />
                          <div className="text-red-500 text-sm mt-1">
                            {formErrors.phoneNumber}
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 gap-10 md:grid-cols-2 sm:grid-cols-2">
                        <div>
                          <input
                            type="text"
                            name="emailAddress"
                            placeholder="Email Address"
                            className={`w-full placeholder:text-mjavsGreen border-2 bg-white p-4 focus:outline-none ${
                              formErrors.emailAddress && "border-red-500"
                            }`}
                            onChange={handleInputChange}
                          />
                          <div className="text-red-500 text-sm mt-1">
                            {formErrors.emailAddress}
                          </div>
                        </div>
                        <div>
                          <input
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            className={`w-full placeholder:text-mjavsGreen border-2 bg-white p-4 focus:outline-none ${
                              formErrors.subject && "border-red-500"
                            }`}
                            onChange={handleInputChange}
                          />
                          <div className="text-red-500 text-sm mt-1">
                            {formErrors.subject}
                          </div>
                        </div>
                      </div>

                      <div>
                        <textarea
                          name="message"
                          placeholder="Your Message"
                          className={`w-full placeholder:text-mjavsGreen border-2 bg-white p-4 focus:outline-none ${
                            formErrors.message && "border-red-500"
                          }`}
                          id=""
                          cols="30"
                          rows="6"
                          onChange={handleInputChange}
                        ></textarea>
                        <div className="text-red-500 text-sm mt-1">
                          {formErrors.message}
                        </div>
                      </div>

                      <button className="w-full font-rajdhani text-sm bg-mjavsGold text-mjavsGreen font-bold py-4 px-2 hover:text-white hover:bg-mjavsGreen transition-all duration-500 ease-in-out hover:bg-gradient-to-r from-mjavsGreen to-mjavsDark delay-100 focus:outline-none md:px-8 md:text-xl">
                        SUBSCRIBE
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default Contact