import { Link } from 'react-router-dom';
import Hero from '../components/Hero';
import { Check, } from 'lucide-react';
import { motion } from 'framer-motion';
import Testimonials from '../components/Testimonials';
import { products, containerVariants } from '../data/data';

import slide1 from '../assets/images/slide1.jpg';
import slide2 from '../assets/images/slide2.jpg';
import slide3 from '../assets/images/slide3.jpg';
import slide5 from '../assets/images/slide5.jpg';
import slide8 from '../assets/images/slide9.jpg';
import slide11 from '../assets/images/slide11.jpg';
import slide12 from '../assets/images/slide12.jpg';
import vacuumtank from '../assets/vacuumtank.jpg';
import company from '../assets/company.jpg';
import company2 from '../assets/company2.jpg';
import pvc1 from '../assets/pvc1.png';
import pvc2 from '../assets/pvc2.png';
import pvc3 from '../assets/pvc3.png';
import pvc4 from '../assets/pvc4.png';


function Home() {
  return (
    <>
      <Hero />
      <section>
        <div className="container my-14 mx-auto space-y-12 flex flex-col md:gap-8 md:my-24 md:flex-row md:space-y-0">
          <div className="mb-12 w-full md:mb-0 md:w-6/12 isolate">
            <div className="relative w-full h-full md:top-[-2.8125rem]">
              <div className="relative w-10/12 h-80 left-[-2.8125rem] bg-mjavsGreen mx-auto" />
                <img 
                  src={company}
                  className="absolute right-[0] top-10 w-11/12 h-full object-cover shadow-lg" alt="about-us"
                  loading="lazy"
                />
            </div>
          </div>

          <div className="w-full md:w-6/12">
            <div
              className="flex h-full items-center text-black">
              <div>
                <span className="block mb-2 text-lg text-mjavsGold tracking-[0.21rem] font-medium">
                  ABOUT US
                </span>
                <h2 className="font-rajdhani mb-8 text-3xl font-bold text-mjavsGreen md:text-4xl lg:text-5xl">Your Gateway to Exceptional PVC Solutions</h2>
                <p className="mb-8 pb-2 text-mjavsGreen font-medium leading-8 text-lg lg:pb-0">
                  A trailblazing force in the realm of Polyvinyl Chloride (PVC) solutions. Established in 2011, we have become synonymous with innovation, quality, and unwavering commitment to excellence.
                </p>
                <div className="grid grid-cols- gap-3 sm:grid-cols-1 md:grid-cols-2">
                  <article className="flex items-center bg-mjavsLight p-3 gap-3 font-medium">
                    <span className="p-2 bg-mjavsGold rounded-full text-mjavsGreen">
                      <Check />
                    </span>
                    <p>Trusted by Industry Leaders</p>
                  </article>
                  <article className="flex items-center bg-mjavsLight p-3 gap-3 font-medium">
                    <span className="p-2 bg-mjavsGold rounded-full text-mjavsGreen">
                      <Check />
                    </span>
                    <p>Quality Assurance</p>
                  </article>
                  <article className="flex items-center bg-mjavsLight p-3 gap-3 font-medium">
                    <span className="p-2 bg-mjavsGold rounded-full text-mjavsGreen">
                      <Check />
                    </span>
                    <p> Innovation Hub</p>
                  </article>
                  <article className="flex items-center bg-mjavsLight p-3 gap-3 font-medium">
                    <span className="p-2 bg-mjavsGold rounded-full text-mjavsGreen">
                      <Check />
                    </span>
                    <p>Unparalleled Expertise</p>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="services bg-mjavsGreen py-12 md:py-24">
        <div className="container flex flex-col space-y-3 md:gap-8 md:flex-row md:space-y-0">
          <div className="md:w-2/5 space-y-5">
            <p className="block mb-2 text-l text-mjavsGold tracking-[0.21rem]">
              WHAT WE DO
            </p>
            <h2 className="font-bold text-white text-4xl font-rajdhani lg:text-5xl">Services for You</h2>
          </div>
          <div className="md:w-3/5">
            <p className="text-white leading-8">
              At MJAVS, we take pride in offering a comprehensive range of Polyvinyl Chloride (PVC) products tailored to meet the diverse needs of our customers. Explore our services and discover how we can elevate your projects with top-notch PVC solutions.
            </p>
          </div>
        </div>

        <div className="container my-8 mx-auto md:px-6 md:my-12">
          <div className="grid gap-x-9 md:grid-cols-3 space-y-9 md:space-y-0">
            {products.map((product) => (
              <div
                key={product.id}
                className="block bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
                <motion.div
                  className="relative overflow-hidden bg-cover bg-no-repeat"
                  variants={containerVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  <div className="w-full h-72 overflow-hidden">
                    <motion.img
                      src={slide8}
                      className="w-full h-full"
                      alt="service_image"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      transition={{ ease: "easeInOut" }}
                    />
                  </div>
                  <motion.svg
                    className="absolute left-0 bottom-[-1px] text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 320"
                  >
                    <motion.path
                      fill="currentColor"
                      d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
                    ></motion.path>
                  </motion.svg>
                </motion.div>
                <div className="p-6 text-center space-y-3">
                  <Link to="/services" className="mb-2 text-lg text-mjavsGreen hover:text-mjavsGold">{product.category}</Link>
                  <p className="">{product.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-12 md:py-24">
        <div className="max-w-[42rem] mx-auto text-center space-y-5 p-[1rem] md:p-0">
          <p className="block mb-2 text-l text-mjavsGold tracking-[0.21rem]">
            PVC APPLICATION
          </p>
          <h2 className="font-bold text-mjavsGreen font-rajdhani text-4xl lg:text-5xl">Explore Premium PVC</h2>
          <p className="text-black leading-8">Explore our services and discover how we can elevate your projects with top-notch PVC solutions.
          </p>
        </div>
        <div className="container my-8 md:my-16">
          <div className="grid gap-6 md:grid-cols-2">
            <div className="mb-6 md:mb-0">
              <div className="grid grid-cols-2 gap-6">
                <div className="shadow-xl w-full py-7 md:py-14">
                  <img src={pvc1}
                    className="w-24 mx-auto" alt="cool" />
                </div>
                <div className="shadow-xl w-full py-7 md:py-14">
                  <img src={pvc2}
                    className="w-24 mx-auto" alt="cool" />
                </div>
                <div className="shadow-xl w-full py-7 md:py-14">
                  <img src={pvc3}
                    className="w-24 mx-auto" alt="cool" />
                </div>
                <div className="shadow-xl w-full py-7 md:py-14">
                  <img src={pvc4}
                    className="w-24 mx-auto" alt="cool" />
                </div>
              </div>
            </div>
            <figure className="w-full h-[30rem] overflow-hidden">
              <img
                src={vacuumtank}
                className="w-full"
                alt="service_image"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ ease: "easeInOut" }}
              />
            </figure>
          </div>
        </div>
      </section>

      <Testimonials />

      <section className="py-16 md:py-28">
        <div className="container flex flex-col items-start gap-8 md:flex-row">
          <div className="w-full md:w-6/12">
            <div className="flex h-full items-center text-black">
              <div>
                <span className="block mb-2 text-lg text-mjavsGold tracking-[0.21rem] font-medium">
                  WELCOME TO MJAVS
                </span>
                <h2 className="font-rajdhani text-3xl font-bold text-mjavsGreen md:text-4xl lg:text-5xl">Why Choose MJAVS ENTERPRISES</h2>
                <p className="my-7 text-mjavsDark leading-8">
                  At MJAVS ENTERPRISES NIGERIA LIMITED, we are not just a supplier, we are your comprehensive partner for PVC excellence. Explore our expanded services and discover how we can contribute to the success of your projects.
                </p>
                <div className="space-y-3">
                  <p><span className="font-bold text-mjavsGreen">Quality Assurance:</span> Rigorous testing and adherence to industry standards ensure the durability and reliability of our PVC products.</p>
                  <p><span className="font-bold text-mjavsGreen">Innovation:</span> Stay ahead with our innovative PVC solutions designed to meet the evolving needs of various industries.</p>
                  <p><span className="font-bold text-mjavsGreen">QCustomization:</span> Tailor-made solutions to fit the unique requirements of your projects.</p>
                  <p><span className="font-bold text-mjavsGreen">Industry Expertise:</span> Benefit from our decade-long experience and industry insights.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-12 w-full md:mb-0 md:w-6/12">
            <div className={`overflow-hidden h-96 md:h-[34rem]`} >
              <img 
                src={company2}
                className="w-full object-cover shadow-lg" alt="about-us"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <hr className="container h-[2px] border-t-0 bg-mjavsLight" />

      <section className="py-16 md:py-28 space-y-10">
        <div className="max-w-[42rem] mx-auto text-center space-y-5 p-[1rem] md:p-0">
          <p className="block mb-2 text-l text-mjavsGold tracking-[0.21rem]">
            PVC Gallery
          </p>
          <h2 className="font-bold text-mjavsGreen font-rajdhani text-4xl lg:text-5xl">Choose Your Style</h2>
        </div>
        <div className="container">
          <div className="flex flex-col md:flex-row">
            <div className="flex w-full md:w-1/2 flex-wrap">
              <div className="w-1/2 p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block h-full w-full object-cover object-center"
                  src={slide1} />
              </div>
              <div className="w-1/2 p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block h-full w-full object-cover object-center"
                  src={slide11} />
              </div>
              <div className="w-full p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block h-full w-full object-cover object-center"
                  src={slide3} />
              </div>
            </div>
            <div className="flex w-full md:w-1/2 flex-wrap">
              <div className="w-full p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block h-full w-full object-cover object-center"
                  src={slide12} />
              </div>
              <div className="w-1/2 p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block h-full w-full object-cover object-center"
                  src={slide2} />
              </div>
              <div className="w-1/2 p-1 md:p-2">
                <img
                  alt="gallery"
                  className="block h-full w-full object-cover object-center"
                  src={slide5} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home