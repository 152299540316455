import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { CgMenuGridR, CgClose } from "react-icons/cg";
import logo from "../assets/logo.png";

function Navbar() {
  const navigation = [
    { name: "HOME", href: "/" },
    { name: "ABOUT", href: "/about" },
    { name: "Services", href: "/services" },
    { name: "CONTACT", href: "/contact" },
  ];
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showShadow, setShowShadow] = useState(false);
  const location = useLocation();
  const isPageActive = location.pathname;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowShadow(true);
      } else {
        setShowShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`bg-white sticky w-full top-0 z-20 font-montserrat transition-all duration-200 ${
      showShadow ? "shadow-md" : ""
    }`}>
      <nav className="flex items-center justify-between py-4 container" aria-label="Global">
        <div className="">
          <Link to="/">
            <span className="sr-only font-semibold md:font-bold text-lg md:text-xl">Mjavs Enterprise Nigeria LTD</span>
            <img src={logo} alt="Mjavs logo" className="h-8 md:h-10 w-auto" />
          </Link>
        </div>
        <ul className="hidden md:flex md:gap-x-8 items-center justify-center">
          {navigation.map((item) => (
            <li key={item.name} className={`${isPageActive ===  item.href ? 'border-b-[0.15rem] border-mjavsGreen' : 'hover:border-b-[0.15rem] border-mjavsGreen'}`}>
              <Link
                to={item.href}
                className="text-sm font-bold leading-6 tracking-[0.22rem] text-mjavsGreen uppercase">
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
        <div className="flex md:hidden">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md text-mjavsGreen"
            onClick={() => setMobileMenuOpen(true)}>
            <span className="sr-only">Open main menu</span>
            <CgMenuGridR className="text-4xl dark:text-white" aria-hidden="true" />
          </button>
        </div>
      </nav>
      <Dialog as="div" className={`md:hidden`} open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50 bg-gray-300 bg-opacity-90  bg-red" />
        <Dialog.Panel className="fixed inset-0 z-50 w-full h-full overflow-auto bg-white text-gray-700 p-[1rem]">
          <div className="flex items-center justify-between">
            <Link 
              to="/" 
              className="font-bold text-xl"
              onClick={() => setMobileMenuOpen(false)}>
              <img src={logo} alt="Mjavs logo" className="h-8 w-auto" />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">Close menu</span>
              <CgClose className="text-3xl text-mjavsGreen" aria-hidden="true" />
            </button>
          </div>
          <div className="flow-root">
            <div className="divide-y divide-gray-500/10">
              <ul className="space-y-2 py-6">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={item.href}
                      onClick={() => setMobileMenuOpen(false)}
                      className="block py-2 text-base font-bold tracking-[0.22rem] text-mjavsGreen uppercase">
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}

export default Navbar;
