import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function Jumbotron({ page }) {
  return (
    <>
      <section
        className="relative h-[30rem] overflow-hidden text-center bg-hero-pattern bg-cover bg-no-repeat font-rajdhani">
        <div
          className="absolute inset-0 h-full w-full bg-[hsla(180,100%,25%,0.8)] overflow-hidden bg-fixed">
          <div className="flex h-full items-center justify-center">
            <div className="text-white">
              <h2 className="mb-4 text-4xl md:text-6xl font-bold">{page}</h2>
              <ul className="flex justify-center gap-3 items-center mb-6 text-2xl font-semibold">
                <li><Link to="/">Home</Link></li>
                <li className="text-4xl font-normal">|</li>
                <li>{page}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

Jumbotron.defaultProps = {
  page: 'Page',
};

Jumbotron.propTypes = {
  page: PropTypes.string,
};

export default Jumbotron