// BackToTop.jsx
import { useEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import { motion } from "framer-motion";
import { IoIosArrowUp } from "react-icons/io";

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Show the back-to-top button when scrolling down, hide it when at the top
      setIsVisible(window.scrollY > 500);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 100 }}
      exit={{ opacity: 0, y: 100 }}
      transition={{ duration: 0.5 }}
      className={`fixed bottom-3 right-3 p-2 bg-gray-800 text-white cursor-pointer opacity-20`}
      onClick={scrollToTop}
    >
      <IoIosArrowUp size={24} />
    </motion.div>
  );
};

export default BackToTop;
