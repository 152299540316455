/* eslint-disable react-hooks/exhaustive-deps */
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import slide1 from '../../assets/images/slide1.jpg';
import slide2 from '../../assets/images/slide2.jpg';
import slide3 from '../../assets/images/slide3.jpg';
import slide4 from '../../assets/images/slide4.jpg';
import slide5 from '../../assets/images/slide5.jpg';
import slide6 from '../../assets/images/slide6.jpg';
import slide7 from '../../assets/images/slide7.jpg';
import slide8 from '../../assets/images/slide8.jpg';
import slide9 from '../../assets/images/slide9.jpg';
import slide10 from '../../assets/images/slide10.jpg';
import slide11 from '../../assets/images/slide11.jpg';
import slide12 from '../../assets/images/slide12.jpg';
import slide13 from '../../assets/images/slide13.jpg';
import slide14 from '../../assets/images/slide14.jpg';
import slide15 from '../../assets/images/slide15.jpg';
import slide16 from '../../assets/images/slide16.jpg';

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const squareData = [
  {
    id: 1,
    src: slide1,
  },
  {
    id: 2,
    src: slide2,
  },
  {
    id: 3,
    src: slide3,
  },
  {
    id: 4,
    src: slide4,
  },
  {
    id: 5,
    src: slide5,
  },
  {
    id: 6,
    src: slide6,
  },
  {
    id: 7,
    src: slide7,
  },
  {
    id: 8,
    src: slide8,
  },
  {
    id: 9,
    src: slide9,
  },
  {
    id: 10,
    src: slide10,
  },
  {
    id: 11,
    src: slide11,
  },
  {
    id: 12,
    src: slide12,
  },
  {
    id: 13,
    src: slide13,
  },
  {
    id: 14,
    src: slide14,
  },
  {
    id: 15,
    src: slide15,
  },
  {
    id: 16,
    src: slide16,
  },
];

const generateSquares = () => {
  return shuffle(squareData).map((sq) => (
    <motion.div
      key={sq.id}
      layout
      transition={{ duration: 1.5, type: "spring" }}
      className="w-full h-full"
      style={{
        backgroundImage: `url(${sq.src})`,
        backgroundSize: "cover",
      }}
    ></motion.div>
  ));
};

const ShuffleGrid = () => {
  const timeoutRef = useRef(null);
  const [squares, setSquares] = useState(generateSquares());

  useEffect(() => {
    shuffleSquares();

    return () => clearTimeout(timeoutRef.current);
  }, []);

  const shuffleSquares = () => {
    setSquares(generateSquares());

    timeoutRef.current = setTimeout(shuffleSquares, 3000);
  };

  return (
    <div className="grid grid-cols-4 grid-rows-4 h-[450px] gap-1">
      {squares.map((sq) => sq)}
    </div>
  );
};

export default ShuffleGrid;