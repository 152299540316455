const containerVariants = {
  initial: {
    opacity: 0,
    scale: 0.9,
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.5,
      ease: "easeInOut", 
    },
  },
  exit: {
    opacity: 0,
    scale: 0.9,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
};

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1280 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 1279, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1023, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

const products = [
  {
    id: 1,
    category: "PVC Pipes and Fittings",
    description: "Our PVC pipes and fittings are engineered to deliver exceptional performance in plumbing and construction projects.",
  },
  {
    id: 2,
    category: "PVC Profiles and Extrusions",
    description: "Explore our range of PVC profiles and extrusions designed to meet the unique requirements of various industries.",
  },
  {
    id: 3,
    category: "Consultation and Support",
    description: "Our commitment goes beyond delivering quality products. We provide support to ensure you make informed decisions.",
  },
];

const pvcProducts = [
  {
    id: 1,
    category: 'PVC Pipes and Fittings',
    description: 'Our PVC pipes and fittings are engineered to deliver exceptional performance in plumbing and construction projects. Whether you\'re working on residential, commercial, or industrial applications, our PVC pipes and fittings offer durability, corrosion resistance, and easy installation.',
  },
  {
    id: 2,
    category: 'PVC Profiles and Extrusions',
    description: 'Explore our range of PVC profiles and extrusions designed to meet the unique requirements of various industries. From custom profiles for construction to specialized extrusions for industrial applications, we provide solutions that enhance efficiency and functionality.',
  },
  {
    id: 3,
    category: 'PVC Sheets and Panels',
    description: 'Discover the versatility of our PVC sheets and panels. Ideal for interior and exterior applications, our sheets and panels are known for their strength, thermal insulation properties, and aesthetic appeal. Choose from a variety of sizes and thicknesses to suit your project requirements.',
  },
  {
    id: 4,
    category: 'Custom PVC Solutions',
    description: 'At MJAVS, we understand that every project is unique. That\'s why we offer custom PVC solutions tailored to your specific needs. Collaborate with our experts to design and manufacture PVC products that align perfectly with your project requirements.',
  },
  {
    id: 5,
    category: 'Consultation and Support',
    description: 'Our commitment to customer satisfaction goes beyond delivering quality products. We provide consultation and support services to ensure you make informed decisions. Whether you need assistance with product selection or technical guidance, our team is here to help.',
  },
  {
    id: 6,
    category: 'PVC Recycling Solutions',
    description: 'Demonstrating our commitment to sustainability, we offer PVC recycling solutions. Partner with us to responsibly manage and recycle PVC materials, contributing to environmental conservation and reducing waste in line with global sustainability goals.',
  }
];

const testimonials = [
  {
    id: 1,
    content: "The team at MJAVS goes above and beyond to provide exceptional service and support. From product selection to technical assistance, they've been a valuable resource for our business.",
    author: "Rita Johnson",
    position: "Project Manager"
  },
  {
    id: 2,
    content: "We've been sourcing PVC products from MJAVS for years, and the consistent quality of their materials speaks volumes. We trust MJAVS to deliver products that meet the highest standards.",
    author: "Michael Anderson",
    position: "Manufacturing Supervisor"
  },
  {
    id: 3,
    content: "Working with MJAVS on a custom PVC project was a seamless experience. Their team took the time to understand our unique requirements and delivered a solution that perfectly fit our needs.",
    author: "Emily Brown",
    position: "Facilities Manager"
  }
];

export { products, containerVariants, responsive, pvcProducts, testimonials };